<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('dealer_management.dealer_requisition')}}</h4>
          </template>
            <template v-slot:body>
                  <b-row>
                   <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.fiscalYear')}}<span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Month"  vid="month_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="month_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.month')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.month"
                                :options="monthList"
                                id="month_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="requisition id"  vid="requisition_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="requisition_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('pump_install.requisition_id')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-input
                                plain
                                v-model="search.requisition_id"
                                id="requisition_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.allocationType')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.allocation_type_id"
                                :options="allocationTypeList"
                                id="allocation_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="dealer code"  vid="dealer_code">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="dealer_code"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerReport.dealer_code')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-input
                                plain
                                v-model="search.dealer_code"
                                id="dealer_code"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    <b-col class="col-sm-2 col-lg-6">
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('dealer_management.dealer_requisition_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        <!-- {{ $n(data.index + 1) }} -->
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(dealer_code)="data">
                                          {{ $n(data.item.dealer_code, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(name_bn)="data">
                                          {{ data.item.dealer_profile.name_bn }}
                                        </template>
                                        <template v-slot:cell(po_memo_no)="data">
                                          {{ $t(data.item.po_memo_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(lc_no)="data">
                                          {{ $t(data.item.lc_no, { useGrouping: false }) }}
                                        </template>
                                          <template v-slot:cell(requisition_date)="data">
                                            {{ data.item.requisition_date|dateFormat }}
                                          </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('dealer_management.d_r_d')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <!-- <template #modal-title class="flex-grow-1">
            {{ $t('movement.portAllocationDetails') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template> -->
          <Details :id="editItemId" :key="editItemId" :items="items" ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { requisitionList, requisitionStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        Details
    },
    data () {
        return {
            search: {
                id: 0,
                month: 0,
                fiscal_year_id: 0,
                allocation_type_id: 0,
                requisition_id: '',
                dealer_code: ''
            },
            items: '',
            lcList: []
        }
    },
    computed: {
      monthList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
      },
      allocationTypeList: function () {
          const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
          const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, text_en: item.text_en, text_bn: item.text_bn, value: item.value }
          } else {
              return { text: item.text_en, text_en: item.text_en, text_bn: item.text_bn, value: item.value }
          }
          })
          return tempData
      },
      fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
          const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
          const tempData = fiscalYears.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, value: item.value }
            } else {
              return { text: item.text_en, value: item.value }
            }
          })
          return tempData
        },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('dealer_panel.ferti_requi') + ' ' + this.$t('globalTrans.entry') : this.$t('dealer_panel.ferti_requi') + ' ' + this.$t('globalTrans.modify')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('pump_install.requisition_id'), class: 'text-left' },
              { label: this.$t('pump_install.requisition_date'), class: 'text-left' },
              { label: this.$t('dealer_management.dealer_name'), class: 'text-left' },
              { label: this.$t('fertilizerReport.dealer_code'), class: 'text-left' },
              { label: this.$t('dealer_management.req_year'), class: 'text-left' },
              { label: this.$t('dealer_management.req_month'), class: 'text-left' },
              { label: this.$t('fertilizerSales.allocationType'), class: 'text-left' },
              { label: this.$t('globalTrans.action'), class: 'text-left' }
          ]

          /*eslint-disable */
          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'requisition_id' },
              { key: 'requisition_date' },
              { key: 'dealer_name_bn' },
              { key: 'dealer_code' },
              { key: 'fiscal_year_bn' },
              { key: 'month_name_bn' },
              { key: 'allocation_type_name_bn' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'requisition_id' },
              { key: 'requisition_date' },
              { key: 'dealer_name' },
              { key: 'dealer_code' },
              { key: 'fiscal_year' },
              { key: 'month_name' },
              { key: 'allocation_type_name' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        details (item) {
          this.items = item
        },
        changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        remove (item) {
            this.toggleStatusCustom(seedFertilizerServiceBaseUrl, requisitionStatus, item)
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, requisitionList, params).then(response => {
                if (response.success) {
                    // this.$store.dispatch('setList', response.data.data)
                    this.$store.dispatch('setList', this.getRelationData(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationData(data) {
          const divisionList = this.$store.state.commonObj.divisionList
          const districtList = this.$store.state.commonObj.districtList
          const upazilaList = this.$store.state.commonObj.upazilaList
          const unionList = this.$store.state.commonObj.unionList
          const allocationTypeList = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList
          const fiscalYearList = this.$store.state.commonObj.fiscalYearList
          const dealerBasicList = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList
          const monthItemDataList = this.monthList
          const listData = data.map((item, index) => {
            const fiscalObj = fiscalYearList.find(fcItem => fcItem.value === item.fiscal_year_id)
            const allocationTypeObj = allocationTypeList.find(type => type.value === item.allocation_type_id)
            const dealerBasicObj = dealerBasicList.find(data => data.value === item.dealer_id)
            const monthDataObj = monthItemDataList.find(mdata => mdata.value === parseInt(item.month))

            const divisionObj = divisionList.find(data=> data.value === dealerBasicObj.per_division_id)
            const districtObj = districtList.find(data=> data.value === dealerBasicObj.per_district_id)
            const upazilaObj = upazilaList.find(data=> data.value === dealerBasicObj.per_upazilla_id)
            const unionObj = unionList.find(data=> data.value === dealerBasicObj.per_union_id)
            const monthdata = {}
            if(monthDataObj !== undefined) {
              monthdata.month_name = monthDataObj.text_en
              monthdata.month_name_bn = monthDataObj.text_bn
            } else {
              monthdata.month_name = ''
              monthdata.month_name_bn = ''
            }
            const fiscalYear = {}
            if(dealerBasicObj !== undefined) {
              fiscalYear.fiscal_year = fiscalObj.text_en
              fiscalYear.fiscal_year_bn = fiscalObj.text_bn
            } else {
              fiscalYear.fiscal_year = ''
              fiscalYear.fiscal_year_bn = ''
            }
            const allocationTypeData = {}
            if(allocationTypeObj !== undefined) {
              allocationTypeData.allocation_type_name = allocationTypeObj.text_en
              allocationTypeData.allocation_type_name_bn = allocationTypeObj.text_bn
            } else {
              allocationTypeData.allocation_type_name = ''
              allocationTypeData.allocation_type_name_bn = ''
            }
            const dealerBasicData = {}
            if(dealerBasicObj !== undefined) {
              dealerBasicData.dealer_name = dealerBasicObj.text_en
              dealerBasicData.dealer_name_bn = dealerBasicObj.text_bn
              dealerBasicData.division_name = divisionObj.text_en
              dealerBasicData.division_name_bn = divisionObj.text_bn
              dealerBasicData.district_name = districtObj.text_en
              dealerBasicData.district_name_bn = districtObj.text_bn
              dealerBasicData.upazila_name = upazilaObj.text_en
              dealerBasicData.upazila_name_bn = upazilaObj.text_bn
              dealerBasicData.union_name = unionObj.text_en
              dealerBasicData.union_name_bn = unionObj.text_bn
            } else {
              dealerBasicData.dealer_name = ''
              dealerBasicData.dealer_name_bn = ''
              dealerBasicData.division_name = ''
              dealerBasicData.division_name_bn = ''
              dealerBasicData.district_name = ''
              dealerBasicData.district_name_bn = ''
              dealerBasicData.upazila_name = ''
              dealerBasicData.upazila_name_bn = ''
              dealerBasicData.union_name = ''
              dealerBasicData.union_name_bn = ''
            }

            return Object.assign({}, item, fiscalYear, allocationTypeData, dealerBasicData, monthdata)
          })
          return listData
        },
        pdfExport () {
          this.$refs.details.pdfExport()
        }
    }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
