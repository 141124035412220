<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12" class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                         <!-- <b-col lg="6" sm="12">
                          <ValidationProvider name="=Dealer Code"  vid="dealer_code">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="dealer_code"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerReport.dealer_code')}}
                              </template>
                              <b-form-input
                                  id="dealer_code"
                                  v-model="formData.dealer_code"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col> -->
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Allocation Date" vid="requisition_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="requisition_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('pump_install.requisition_date')}} <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                          v-model="formData.requisition_date"
                                          :placeholder="$t('globalTrans.select_date')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                              ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Lot No"  vid="lot_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="lot_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('pusti_mapping.month')}} <span class="text-danger">*</span>
                              </template>
                               <b-form-select
                                plain
                                v-model="formData.month"
                                :options="monthList"
                                id="month"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                      <b-thead head-variant="secondary">
                        <b-tr>
                          <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th>{{ $t('fertilizerConfig.fertilizer_name') }}</b-th>
                          <b-th>{{ $t('movement.quantity') }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="(portInfoItem, index) in formData.requisition_details" :key="index">
                          <b-th>{{ $n(index+1) }}</b-th>
                          <b-th>{{ currentLocale === 'en' ? portInfoItem.fertilizer_name : portInfoItem.fertilizer_name_bn }}</b-th>
                          <b-th class="text-right">
                            <b-form-group
                            >
                              <b-form-input
                                  :id="'allocated_amount_'+(index+1)"
                                  :ref="'allocated_amount_'+(index+1)"
                                  type="number"
                                  step="0.01"
                                  v-model="portInfoItem.quantity"
                              ></b-form-input>
                            </b-form-group>
                          </b-th>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { requisitionStore, requisitionUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.formData.requisition_details = []
      const fertilizerDetailsInfo = tmp.dealer_requisition_details
      this.fertilizerNameList.filter(item => {
        const fertiDetailsItem = fertilizerDetailsInfo.find(portDetailsObj => portDetailsObj.fertilizer_id === item.value)
        const portInfoItem = {
          requisition_details_id: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.id : 0,
          fertilizer_id: item.value,
          quantity: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.quantity : '',
          fertilizer_name: item.text_en,
          fertilizer_name_bn: item.text_bn
        }
        this.formData.requisition_details.push(portInfoItem)
      })
    } else {
      this.fertilizerNameList.filter(item => {
        const portInfoItem = {
          fertilizer_id: item.value,
          quantity: '',
          fertilizer_name: item.text_en,
          fertilizer_name_bn: item.text_bn
        }
        this.formData.requisition_details.push(portInfoItem)
      })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        fiscal_year_id: 0,
        month: 0,
        requisition_details: []
      }
    }
  },
  computed: {
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    fiscalYearList: function () {
      const fiscalyear = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyear.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    }
  },
  watch: {
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${requisitionUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, requisitionStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.success({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
    }
  }
}
</script>
